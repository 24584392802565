<template>
  <div class="my-wrapper">
    <div class="my-list-wrapper">
      <!-- <ListItem
        title="更改手机"
        @handleListItemClick="handleSetClick('/modify/phone')"
      >
        <i class="iconfont iconyou" slot="value"></i>
      </ListItem> -->
      <ListItem
        title="修改密码"
        :borderBottom="borderBottomStatus"
        @handleListItemClick="handleSetClick('/modify/password')"
      >
        <i class="iconfont iconyou" slot="value"></i>
      </ListItem>
    </div>
  </div>
</template>

<script>
  import ListItem from '@/components/ListItem'
  export default {
    name: 'Set',
    components: {
      ListItem
    },
    data () {
      return {
        borderBottomStatus: false,
      }
    },
    methods: {
      handleSetClick (url) {
        this.$router.push(url)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/mixin";
  .my-wrapper{
    @include wh(100%, 100%);
    background-color: #F1F1F1;
    box-sizing: border-box;
    padding-top: 1rem;
    .my-list-wrapper{
      @include wh(100%, 3rem);
      background-color: white;
      i.iconfont{
        font-size: 1rem;
        color: #4A8CFF;
      }
      i.iconyou{
        color: #999999;
        font-size: 0.7rem;
      }
    }
  }
</style>
